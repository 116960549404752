module.exports = {
  customer: 'kirklees',
  environment: 'production',
  yourMoney: true,
  jobsVolunteering: true,
  googleAnalyticsId: 'G-EVEMW3ZHLL',
  robots: true,
  chatWidget: {
    htmlTemplateName: 'libertyconnect',
    workspaceId: 'ec0ea492-6cdc-409f-8507-8fd184605879'
  },
  entitledToBaseUrl: 'https://kirkleesbetteroff.entitledto.co.uk',
  entitledToReassessUrl: 'https://kirkleesbetteroff.entitledto.co.uk/reassessbetteroff',
  requirePostcode: true,

  app: {
    home: 'https://www.betteroffkirklees.org.uk'
  },
  serverBaseUrl: 'https://api.betteroffkirklees.org.uk',
  api: {
    baseUrl: 'https://api.betteroffkirklees.org.uk/internal/v1'
  },
  authGateway: 'https://account.betteroffkirklees.org.uk',
  loginServices: [
    {name: 'kerp'},
    {name: 'phone'},
    {name: 'google', label: 'Google', deprecatedUntil: '2024-01-02', postLogoutUrl: 'https://accounts.google.com/Logout'},
    {name: 'facebook', label: 'Facebook', deprecatedUntil: '2024-01-02'},
    {name: 'agent'},
    {name: 'referenceNumber'}
  ],
  ui: {
    showJobSearchJournal: true,
    showOfflineForms: true,
    showAssessmentMutators: false,
    restrictions: {
      applications: false
    }
  },
  airbrake: {
    enabled: true,
    projectId: 131082,
    projectKey: 'facc9a060872b357453b677f15d319e3'
  }
};
